import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				Ласкаво просимо до NewCreative Pulse
			</title>
			<meta name={"description"} content={"Де кожна повторення має значення\n"} />
			<meta property={"og:title"} content={"Ласкаво просимо до NewCreative Pulse"} />
			<meta property={"og:description"} content={"Де кожна повторення має значення\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/2-1.jpg?v=2024-09-23T13:07:13.313Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139cc9e74900024fd1b84/images/6422889.png?v=2024-09-23T12:46:53.526Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				text-align="left"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Політика конфіденційності
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Згідно Закону України від 01 червня 2010 року № 2297-VI «Про захист персональних даних» (надалі – «Закон») під персональними даними розуміють відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована. Згідно Політики конфіденційності в поняття "особистої інформації" входить інформація, або частина інформації, котра дозволяє Вас ідентифікувати. Як правило сюди входить ім’я та прізвище, псевдонім (нік), адреса електронної пошти та номер телефону, може також входити така інформація, як IP-адреса. Такі відомості ми можемо отримати безпосередньо від Вас, наприклад, при реєстрації на сайті, наданні контактних даних, інформації отриманої із банківської картки або платежів, а також іншу особисту інформацію, яку Ви добровільно надали нам.
					<br />
					<br />
					Компанія з великою повагою ставиться до конфіденційної (персональної) інформації всіх без винятку осіб, які відвідали сайт, а також тих, хто користується наданими сайтом послугами; в зв'язку з чим, Компанія прагне захищати конфіденційність персональних даних (відомостей чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована), тим самим створивши і забезпечивши максимально комфортні умови використання сервісів Сайту кожному користувачеві.
				</Text>
				<Text
					margin="20px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Використання та мета збору персональних даних
				</Text>
				<Text
					margin="5px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Особиста інформація відвідувачів сайту, зібрана при реєстрації, переважно використовується для надання послуг у відповідності з Вашими потребами, в першу чергу для надання послуг або у договірних відносинах, а також для відповідей на Ваші запитання. Також ми можемо використовувати Вашу контактну інформацію в поштових відправленнях, а саме повідомляти Вас про нові можливості, акції та інші новини. Ми діємо відповідно до цієї Політики конфіденційності, на підставі Положення про обробку і захист персональних даних та на підставі чинного законодавства України.
					<br />
					<br />
					Ми маємо право зберігати Персональні дані стільки, скільки необхідно для реалізації мети, що зазначена у даній Політиці або у строки, встановлені чинним законодавством України або до моменту видалення Вами цих даних.
				</Text>
				<Text
					margin="20px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Надання доступу до інформації
				</Text>
				<Text
					margin="5px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Надання доступу до особистої інформації іншим компаніям і приватним особам можливе лише за таких наступних обставин:
					<br />
					<br />
					Має місце наявність дозволу Користувача. Для передачі будь-якої конфіденційної інформації потрібна явна згода Користувача.
					<br />
					В випадках визначених чинним законодавством України, а також у випадках запобігання злочину або завдання шкоди нам або третім особам.
				</Text>
				<Text
					margin="20px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Захист інформації
				</Text>
				<Text
					margin="5px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Ми вживаємо всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи знищення. До цих заходів належать, зокрема, внутрішня перевірка процесів збору, зберігання та обробки даних і заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для запобігання неавторизованого доступу до систем, в яких ми зберігаємо особисті дані.
				</Text>
				<Text
					margin="20px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Зміна політики конфіденційності
				</Text>
				<Text
					margin="5px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Користувач приймає умови цієї політики конфіденційності і враховує, що дана політика конфіденційності може час від часу змінюватися. Зміни, що вносяться до політики конфіденційності, публікуються на цій сторінці. З питань щодо політики конфіденційності (в т.ч. видалення персональних даних), Користувач може зв'язатися з нами використовуючи e-mail: contact@newcreativepulse.com
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});